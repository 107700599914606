<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="Pickup"
      tooltip-title="SETTINGS_PICKUP"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght100 main-content bg-primary-white mt-5 p-5">
      <div v-if="isLoading" class="mt-28">
        <Loader :content="true" />
      </div>
      <div v-else class="mt-10 pl-5 pr-10">
        <div
          class="flex md:flex-row flex-col md:items-center items-start md:gap-0 gap-5 justify-between pb-4"
        >
          <div class="flex items-center gap-5">
            <div class="text-text-label md:text-lg text-xl">Request Time</div>
            <div>
              <UiLock
                v-model="settings.is_locked_pickup_time"
                :deactivate="settings.is_locked_at_upper_level_pickup_time"
              />
            </div>
          </div>
          <div class="flex items-center gap-5">
            <div class="flex items-center">
              <UiTimePicker
                v-model="settings.pickup_start_time"
                type="time"
                rules="required"
                :disabled="settings.is_locked_at_upper_level_pickup_time"
                :title="$t(`title.START_TIME`)"
                class="w-56"
              ></UiTimePicker>
            </div>
            <div class="flex items-center">
              <UiTimePicker
                v-model="settings.pickup_end_time"
                type="time"
                rules="required"
                :disabled="settings.is_locked_at_upper_level_pickup_time"
                :title="$t(`title.END_TIME`)"
                class="w-56"
              ></UiTimePicker>
            </div>
          </div>
        </div>

        <div
          class="flex md:flex-row flex-col md:items-center items-start md:gap-0 gap-5 justify-between"
        >
          <div class="flex items-center gap-5">
            <div class="text-text-label md:text-lg text-xl">
              Request Expire After Last Inactivity
            </div>
            <div>
              <UiLock
                v-model="settings.expire_after_mins_last_activity.is_locked"
                :deactivate="settings.expire_after_mins_last_activity.is_locked_at_upper_level"
              />
            </div>
          </div>
          <div class="flex items-center">
            <Counter
              v-model="settings.expire_after_mins_last_activity.value"
              :disabled="settings.expire_after_mins_last_activity.is_locked_at_upper_level"
              class="w-56"
            />
          </div>
        </div>
        <div
          class="flex md:flex-row flex-col md:items-center items-start md:gap-0 gap-5 justify-between mt-10"
        >
          <div class="flex items-center gap-5">
            <div class="text-text-label md:text-lg text-xl">Time Duration Re Request</div>
            <div>
              <UiLock
                v-model="settings.allow_rerequest_after_mins.is_locked"
                :deactivate="settings.allow_rerequest_after_mins.is_locked_at_upper_level"
              />
            </div>
          </div>
          <div class="flex items-center">
            <Counter
              v-model="settings.allow_rerequest_after_mins.value"
              :disabled="settings.allow_rerequest_after_mins.is_locked_at_upper_level"
              class="w-56"
            />
          </div>
        </div>
        <div
          class="flex md:flex-row flex-col md:items-center items-start md:gap-0 gap-5 justify-between mt-10"
        >
          <div class="flex items-center gap-5">
            <div class="text-text-label md:text-lg text-xl">Allowed Re Requests Per Day</div>
            <div>
              <UiLock
                v-model="settings.max_allowed_rerequests.is_locked"
                :deactivate="settings.max_allowed_rerequests.is_locked_at_upper_level"
              />
            </div>
          </div>
          <div class="flex items-center">
            <Counter
              v-model="settings.max_allowed_rerequests.value"
              :disabled="settings.max_allowed_rerequests.is_locked_at_upper_level"
              duration="time"
              class="w-56"
            />
          </div>
        </div>
        <div
          class="flex md:flex-row flex-col md:items-center items-start md:gap-0 gap-5 justify-between mt-10"
        >
          <div class="flex items-center gap-5">
            <div class="text-text-label md:text-lg text-xl">Student Dispatcher</div>
            <div>
              <UiLock
                v-model="settings.is_locked_pickup_assignee"
                :deactivate="settings.is_locked_at_upper_level_pickup_assignee"
              />
            </div>
          </div>
          <div class="flex items-center -mb-5">
            <UiSingleSelect
              v-model="settings.pickup_assignee_id"
              :hide-title="true"
              :no-error="true"
              :image="true"
              class="w-60"
              :disabled="settings.is_locked_at_upper_level_pickup_assignee"
              :options="stafflist"
              label="full_name"
              reduce="id"
              :search-through-api="true"
              @search="getAllStaffAndTeacher"
            />
          </div>
        </div>
        <div class="pb-5 mt-20">
          <button
            class="mt-5 mb-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
            @click="updatePickupSettings"
          >
            {{ $t('dashboard.Save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import UiTimePicker from '@/src/components/UiElements/UiTimePicker.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Counter from '@src/components/UiElements/UICounter.vue'
import { mapActions, mapState } from 'vuex'
import UiLock from '@components/UiElements/UiLockAnimation.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'

export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    Counter,
    UiTimePicker,
    UiLock,
    Loader,
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      isLoading: false,
      stafflist: [],
      settings: null,
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout?.currentCampusScope || '',
      currentSectionScope: (state) => state.layout?.currentSectionScope || '',
    }),
    changeLevel() {
      return this.currentCampusScope.id + this.currentSectionScope.id
    },
  },
  watch: {
    changeLevel: {
      handler() {
        this.getSettings()
        this.getAllStaffAndTeacher()
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getAllStaffAndTeacher()
      this.getSettings()
    },
    getSettings() {
      this.isLoading = true
      this.getPickupSettings().then((settings) => {
        this.settings = settings
        if (this.settings && !this.settings.pickup_assignee) {
          this.settings.pickup_assignee = {}
        } else if (this.settings && this.settings.pickup_assignee) {
          fullName(this.settings.pickup_assignee)
        }
        this.isLoading = false
      })
    },
    getAllStaffAndTeacher(query = '') {
      let filterPayload = { $where: buildWhereQuery(FILTER_KEYS.ILIKE, 'first_name', query) }
      const payload = { ...filterPayload, limit: GENERAL_CONSTANTS.RECORD_LIMIT_FOR_FILTERS }
      this.fetchStaffAndTeacherList(payload).then((res) => {
        this.stafflist = res.records
        if (
          this.settings?.pickup_assignee?.id &&
          !this.stafflist.some((staff) => staff.id === this.settings?.pickup_assignee?.id)
        ) {
          this.stafflist.push(this.settings?.pickup_assignee)
        }
      })
    },

    updatePickupSettings() {
      let data = { payload: this.settings, id: this.settings?.id }
      this.setPickcupSettings(data).then((updatedSettings) => {
        this.getSettings()
        this.getAllStaffAndTeacher()
      })
    },
    ...mapActions('staff', ['fetchStaffAndTeacherList']),
    ...mapActions('settings', ['setPickcupSettings', 'getPickupSettings']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
