<template>
  <div class="flex gap-5 items-center">
    <div class="flex gap-5 items-center">
      <div
        :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="updateCounterValue(-1)"
      >
        <icon icon="minus" width="18" height="18" color="primary-green" />
      </div>
      <div>
        <UiInputBox
          ref="counterContainer"
          v-model.number="counter"
          type="text"
          name="Counter"
          title="Counter"
          label="Counter"
          :hide-title="true"
          :no-error="true"
          placeholder=""
          class="w-24"
          :disabled="disabled"
          :is-not-translated="true"
        />
      </div>
      <div
        :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="updateCounterValue(+1)"
      >
        <icon icon="plus" width="18" height="18" color="primary-green" />
      </div>
    </div>
    <div class="text-primary-green text-lg font-semibold">{{ getDuration }}</div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
import UiInputBox from '@components/UiElements/UiInputBox.vue'
export default {
  components: {
    icon,
    UiInputBox,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    duration: {
      type: String,
      default: 'min',
    },
    modelValue: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      counter: 1,
    }
  },
  computed: {
    getDuration() {
      return this.counter > 1 ? this.duration + 's' : this.duration
    },
  },
  watch: {
    counter: {
      immediate: true,
      handler(value) {
        this.$emit('update:modelValue', value)
      },
    },
    modelValue: {
      immediate: true,
      handler(val) {
        this.counter = val
      },
    },
  },
  methods: {
    updateCounterValue(value) {
      if (!this.disabled) this.counter += value
    },
  },
}
</script>
